@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  line-height: 1.35;
  font-family: TradeGothicLT, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 200;
}

body {
  color: #1f1f1f;
  font-family: "TradeGothicLT";
  scroll-behavior: smooth;
}

a {
  color: #1f1f1f;
  font-family: "TradeGothicLT";
}
button {
  font-family: "TradeGothicLT";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-family: "TradeGothicLT";
  font-weight: 700;
  color: #0731a1;
  font-weight: 800;
}

@font-face {
  font-family: "TradeGothicLT";
  src: local("TradeGothicLT"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.eot") format("eot"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.ttf") format("ttf"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "TradeGothicBd";
  src: local("TradeGothicBd"),
    url("Fonts/TradeGothic/TradeGothic_Bold.eot") format("eot"),
    url("Fonts/TradeGothic/TradeGothic_Bold.ttf") format("ttf"),
    url("Fonts/TradeGothic/TradeGothic_Bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "BrandonReg";
  src: local("BrandonReg"),
    url("Fonts/BrandonGrotesk/Brandon_reg.eot") format("eot"),
    url("Fonts/BrandonGrotesk/Brandon_reg.ttf") format("ttf"),
    url("Fonts/BrandonGrotesk/Brandon_reg.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BrandonRegIt";
  src: local("BrandonRegIt"),
    url("Fonts/BrandonGrotesk/Brandon_reg_it.eot") format("eot"),
    url("Fonts/BrandonGrotesk/Brandon_reg_it.ttf") format("ttf"),
    url("Fonts/BrandonGrotesk/Brandon_reg_it.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BrandonMed";
  src: local("BrandonMed"),
    url("Fonts/BrandonGrotesk/Brandon_med.eot") format("eot"),
    url("Fonts/BrandonGrotesk/Brandon_med.ttf") format("ttf"),
    url("Fonts/BrandonGrotesk/Brandon_med.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "BrandonBld";
  src: local("BrandonBld"),
    url("Fonts/BrandonGrotesk/Brandon_bld.eot") format("eot"),
    url("Fonts/BrandonGrotesk/Brandon_bld.ttf") format("ttf"),
    url("Fonts/BrandonGrotesk/Brandon_bld.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}

.uppercase {
  text-transform: uppercase;
}
