@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.35;
  font-family: TradeGothicLT, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 200;
}

body {
  color: #1f1f1f;
  font-family: "TradeGothicLT";
  scroll-behavior: smooth;
}

a {
  color: #1f1f1f;
  font-family: "TradeGothicLT";
}
button {
  font-family: "TradeGothicLT";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-family: "TradeGothicLT";
  font-weight: 700;
  color: #0731a1;
  font-weight: 800;
}

@font-face {
  font-family: "TradeGothicLT";
  src: local("TradeGothicLT"),
    url(../../static/media/Trade_Gothic_LT.c26a09eb.eot) format("eot"),
    url(../../static/media/Trade_Gothic_LT.9efed080.ttf) format("ttf"),
    url(../../static/media/Trade_Gothic_LT.aa924b16.woff) format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "TradeGothicBd";
  src: local("TradeGothicBd"),
    url(../../static/media/TradeGothic_Bold.898ab1b4.eot) format("eot"),
    url(../../static/media/TradeGothic_Bold.e07f84a3.ttf) format("ttf"),
    url(../../static/media/TradeGothic_Bold.a5aebff2.woff) format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "BrandonReg";
  src: local("BrandonReg"),
    url(../../static/media/Brandon_reg.533cc2cf.eot) format("eot"),
    url(../../static/media/Brandon_reg.719bc3a5.ttf) format("ttf"),
    url(../../static/media/Brandon_reg.d4c7bb85.woff) format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BrandonRegIt";
  src: local("BrandonRegIt"),
    url(../../static/media/Brandon_reg_it.ecc83588.eot) format("eot"),
    url(../../static/media/Brandon_reg_it.86580124.ttf) format("ttf"),
    url(../../static/media/Brandon_reg_it.a8380c5f.woff) format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BrandonMed";
  src: local("BrandonMed"),
    url(../../static/media/Brandon_med.a6580f67.eot) format("eot"),
    url(../../static/media/Brandon_med.3eee5078.ttf) format("ttf"),
    url(../../static/media/Brandon_med.f3849e76.woff) format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "BrandonBld";
  src: local("BrandonBld"),
    url(../../static/media/Brandon_bld.fbf39ba3.eot) format("eot"),
    url(../../static/media/Brandon_bld.b8f46ce5.ttf) format("ttf"),
    url(../../static/media/Brandon_bld.d5afe196.woff) format("woff");
  font-weight: 800;
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.757eaf58.otf) format("opentype");
}

.uppercase {
  text-transform: uppercase;
}

